
<template>
  <div class='vant-box'>
    <div class="container">
        <!-- 控件一 -->
          <!-- vant 表单 -->
          <van-form @submit="onSubmit" >
            <div class="form-item form-item1">
              <div class='text1'>
                <span style="color: #F56C6C">* </span>
              <span class='text1-title'>企业名称</span>
              <van-field  v-model="company" name="company"  placeholder="用户名" clearable
             />
              </div>
              <div class='text1'>
              <span class='text1-title'>联系人</span>
              <van-field  v-model="username"  name="username"  placeholder="密码"/>
              </div>
              <div class='text1'>
                <span class='text1-title'>联系方式</span>
                <van-field  v-model="mobile"  name="mobile"  placeholder="密码"/>
              </div>
              <div class="form-item">
                <!-- 上传组件 -->
                 <van-uploader :after-read="afterRead" />
                <van-uploader v-model="fileList" name="fileList" multiple />
              </div>
            </div>
            <div style="margin: 16px;">
              <van-button   type="info" >提交</van-button>
            </div>

          </van-form>

    </div>
  </div>
</template>

<script>
import {
  collectAdd, uploadSign
} from '../../http'
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import Vue from 'vue'
import { Toast, Dialog } from 'vant'
import areaList from '@/assets/js/area.js'
import { shareApp } from '@/http'
Vue.use(Toast)
Vue.use(Dialog)

export default {
  name: 'sign',
  // import引入的组件需要注入到对象中才能使用
  components: {

  },
  data() {
    // 这里存放数据
    return {
      active: 0,
      company: '',
      username: '',
      mobile: '',
      password: '',
      date: '', // 单日日历
      dateRange: '', // 区间日历
      show: false,
      rangeShow: false,
      keyValue: '', // 软键盘
      keyShow: false,
      passwordValue: '',
      showKeyboard: true,
      radios: 1, // 单选框
      checked: true, // 复选框
      checked2: false,
      checked3: false,
      rateValue: '', // 评分
      rateValue1: 3, // 评分禁用
      rateValue2: '',
      rateValue3: 3.5,
      rateValue4: 3, // 评分只读
      sliderValue: 60, // 步进器
      SwitchChecked: false, // switch 开关
      SwitchChecked1: true,
      SwitchChecked2: true,
      SwitchChecked3: true,
      SwitchChecked4: false,
      fileList: [
        { url: 'https://img.yzcdn.cn/vant/leaf.jpg' },
        // Uploader 根据文件后缀来判断是否为图片文件
        // 如果图片 URL 中不包含类型信息，可以添加 isImage 标记来声明
        { url: 'https://img.yzcdn.cn/vant/leaf.jpg', isImage: true }
      ],
      dialogShow: false,
      showShare: false,
      options: [
        { name: '微信', icon: 'wechat' },
        { name: '微博', icon: 'weibo' },
        { name: 'QQ', icon: 'qq' },
        { name: '复制链接', icon: 'link' },
        { name: '分享海报', icon: 'poster' },
        { name: '二维码', icon: 'qrcode' }
      ],

      time: 30 * 60 * 60 * 1000, // 倒计时
      tel: '',
      text: '',
      digit: '',
      number: '',
      passwords: '',
      iptValue1: '',
      iptValue2: '',
      sms: '', // 验证码
      message: '',
      txtTotalNum: '',
      textAlignRight: '',
      pickerValue: '',
      columns: ['杭州', '武汉', '黄冈', '深圳', '上海', '北京'],
      showPicker: false,
      Areavalue: '',
      showArea: false,
      areaLists: null,
      sheetShow: false,
      actions: [
        { name: '选项一' },
        { name: '选项二 (禁用)', disabled: true },
        { name: '选项三' },
        { name: '选项四', subname: '描述信息' }
      ]
    }
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.areaLists = areaList
  },
  // 方法集合
  methods: {
    onSubmit(values) {
      // eslint-disable-next-line no-undef
       collectAdd({ info: JSON.stringify(values) }).then((res) => {
         if (res.code === 200) {
           // 添加成功
           Toast('报名成功')
           this.$router.push('/success')
         }
       })
    },
    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`
    },
    onConfirm(date) {
      this.show = false
      this.date = this.formatDate(date)
    },
    // 区间日历控件
    onConfirmRange(date) {
      const [start, end] = date
      this.rangeShow = false
      this.dateRange = `${this.formatDate(start)} - ${this.formatDate(end)}`
    },
    vanBtn() {
      Toast('我是成功按钮')
    },
    vanLoad() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      })
    },
    // 软键盘输入密码
    onInput(key) {
      this.passwordValue = (this.passwordValue + key).slice(0, 6)
    },
    onDelete() {
      this.passwordValue = this.passwordValue.slice(0, this.passwordValue.length - 1)
    },
    // 步进器
    onChange(value) {
      Toast(value)
    },
    // switch 开关确认弹窗
    onInputs(checked) {
      Dialog.confirm({
        title: '提醒',
        message: '是否切换开关？'
      }).then(() => {
        this.SwitchChecked4 = checked
      })
    },
    // 上传
    // afterRead(file) {
    //   uploadSign().then(
    //     (data) => {
    //       data.secure = true
    //       // eslint-disable-next-line new-cap
    //       let OSS = new oss(data)
    //       Vue.prototype.$oss = OSS
    //     },
    //     (err) => console.log(err)
    //   )
    //   // 此时可以自行将文件上传至服务器
    //     this.$oss
    //       .put(
    //         `/collect/`,
    //         file
    //       )
    //       // ${file.name.split(".")[1]}
    //       .then((data) => {
    //         Toast(data)
    //       })
    // },
    // 弹窗
    dialogBtn() {
      Dialog.alert({
        title: '标题啊',
        message: '内容啊'
      }).then(() => {
        Toast('确定了')
      })
    },
    dialogConfirmBtn() {
      Dialog.confirm({
        title: '标题啊',
        message: '确认框内容哦123',
        cancelButtonText: '取消哦'
      }).then(() => {
        Toast('确定了')
      }).catch(() => {
        Toast('取消了')
      })
    },
    // 分享面板
    onSelect(option) {
      Toast(option.name)
      this.showShare = false
    },
    // 滑动单元格
    beforeClose({ position, instance }) {
      switch (position) {
        case 'left':
        case 'cell':
        case 'outside':
          instance.close()
          break
        case 'right':
          Dialog.confirm({
            message: '确定删除吗？'
          }).then(() => {
            instance.close()
            Toast('确定了')
          })
          break
      }
    },
    // 倒计时
    start() {
      this.$refs.countDown.start()
    },
    pause() {
      this.$refs.countDown.pause()
    },
    reset() {
      this.$refs.countDown.reset()
    },
    finish() {
      Toast('倒计时结束')
    },
    // 选择器
    onConfirms(value) {
      this.pickerValue = value
      this.showPicker = false
    },
    onConfirmArea(values) {
      this.Areavalue = values.map((item) => item.name).join('/')
      this.showArea = false
    },
    onSelects(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      Toast(item.name)
    },
    // 动作面板
    onCancel() {
      Toast('取消了...')
    }
  }
}
</script>

<style lang='less' scoped>
.vant-box {
  .container {
    font-size: 20px;
    margin: 20px 50px;
    .van-password-input {
      margin: 0;
    }

    .form-item {
      .text1{
        margin: 30px 0;
        .text1-title{
          margin: 10px 0;
        }
      }
      .van-button {
        display: inline-block;
        width: 30%;
        margin: 0 10px 10px 0;
      }
    }

    .form-item3,
    .form-item4,
    .form-item5,
    .form-item6,
    .form-item7,
    .form-item8,
    .form-item9 {
      padding: 0 10px;
    }

    .form-item4 {
      .van-radio {
        margin-bottom: 10px;
      }
    }

    .form-item5 {
      .van-checkbox {
        margin-bottom: 10px;
      }
    }

    .form-item6 {
      .van-rate {
        width: 60%;
        margin-bottom: 10px;
      }
    }

    .form-item8 {
      .van-switch {
        margin-right: 15px;
      }
    }

    .form-item9 {
      .van-count-down {
        margin-bottom: 10px;
      }
    }

    .form-item10 {}

    .goods-card {
      margin: 0;
      background-color: #fff;
    }

    .delete-button {
      height: 100%;
    }

    .my-swipe .van-swipe-item {
      color: #fff;
      font-size: 20px;
      line-height: 150px;
      text-align: center;
      background-color: #39a9ed;
    }
  }
}
</style>

